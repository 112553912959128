import React from "react"
import bgImage from "../assets/mccalla-coaching-header-background.webp"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"
import PageHeader from "../components/PageHeader"
import { Container } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import ContactForm from "../components/ContactForm"

const IndexPage = () => (
  <>
    <SEO title="Contact" description="Contact Michael McCalla Coaching for helping you achieve your goals in the disciplines of cross-country and marathon mountain biking, gravel, cyclocross and road events."/>
    <PageHeader backgroundImage={bgImage} backgroundImageHeight={30} backgroundTextTopPosition={"50%"}>
      <Typography variant="h1">Contact Me</Typography>
    </PageHeader>
    <Container maxWidth="md">
      <Box marginY={10} marginX={2} align="center">
        <ContactForm/>
      </Box>
    </Container>
  </>
)

export default IndexPage
