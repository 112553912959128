import React from "react"
import {
  Button, Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  TextField
} from "@material-ui/core"
import Box from "@material-ui/core/Box"

const INTERESTS = ["Mountain Bike XC", "Mountain Bike Marathon", "Road Racing/Time Trial", "Gravel", "Cyclocross", "Multi-Sport"]

const ContactForm = () => {
  return (
    <form method="post" data-netlify="true" data-netlify-honeypot="bot-field">
      <input type="hidden" name="form-name" value="contact" />
      <Paper style={{ padding: 16 }}>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="email"
              fullWidth
              required
              type="email"
              label="Email"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="firstName"
              type="text"
              label="First Name"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="lastName"
              type="text"
              label="Last Name"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="city"
              label="City"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="country"
              label="Country"
            />
          </Grid>
          <Grid item>
            <Box marginTop={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Which of the following interests you?</FormLabel>
                <FormGroup row>
                  {INTERESTS.map(interest => (
                    <FormControlLabel
                      key={interest}
                      label={interest}
                      control={
                        <Checkbox
                          color="primary"
                          name="interests"
                          type="checkbox"
                          value={interest}
                        />
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="notes"
              multiline
              label="What would you like to tell us about yourself?"
            />
          </Grid>
          <Grid item style={{ marginTop: 16 }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </form>
  )
}

export default ContactForm